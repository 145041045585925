// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  instagram_token: 'INSTA_TOKEN',
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN',
  firebaseConfig: {
    apiKey: "AIzaSyA0mDRwaL5qAll2uYvPWcCU_Da-U_QUi9Q",
    authDomain: "orvity-6d522.firebaseapp.com",
    projectId: "orvity-6d522",
    storageBucket: "orvity-6d522.appspot.com",
    messagingSenderId: "1062198452470",
    appId: "1:1062198452470:web:f7531c0ba53108ea014cd7",
    measurementId: "G-K4VXXKBNDL"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
