<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div [routerLink]="['/home/buscador']">
        <img src="assets/images/icon/search_orange.png" class="img-fluid" alt="search">
        <i class="ti-search"></i>
      </div>
    </li>
    <li class="onhover-div mobile-setting" *ngIf="localuid!==null&&localuid!==''">
      <div (click)="logout()">
        <img src="assets/images/icon/settings-outline.png" class="img-fluid" alt="settong">
        <i class="ti-settings"></i>
      </div>
    </li>
    <li class="onhover-div mobile-cart">
      <div>
        <a [routerLink]="['/home/cart']">
          <img src="assets/images/icon/cart-outline.png" class="img-fluid" alt="cart">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ products.length }}</span>
      <ul class="show-div shopping-cart" *ngIf='!products.length'>
        <h5>Tu carrito esta vacio</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='products.length'>
        <li *ngFor="let product of products">
          <div class="media">
            <a [routerLink]="['/home/producto', product.id]">
              <img class="mr-3" [defaultImage]="'assets/images/item.png'"
                           [lazyLoad]="product.imageProduct" [alt]="product?.title">
            </a>
            <div class="media-body">
              <a [routerLink]="['/home/producto', product.id]">
                <h4>{{ product?.title }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.cantidad }} x
                  {{ product?.price * productService?.Currency.price | discount:product | currency:productService?.Currency.currency:'symbol' }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(product)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/home/cart']" class="view-cart">Ver carrito</a>
            <a [routerLink]="['/home/checkout']" class="checkout">Enviar</a>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>