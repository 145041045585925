<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">

    </div>
    <div class="front">
      <a [routerLink]="['/home/producto', product.id]">
        <img
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/item.png'"
          [lazyLoad]="ImageSrc ? ImageSrc : product.imageProduct"
          class="img-fluid lazy-loading" alt="{{product.title}}" style="height:200px;object-fit:contain;" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/home/producto', product.id]">
        <img [src]="ImageSrc ? ImageSrc : product.imageProduct" class="img-fluid lazy-loading" alt="{{ product.title }}" style="height:200px;object-fit:contain;">
      </a>
    </div>
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <a class="mt-3" [routerLink]="['/home/producto', product?.id]">
        <h6>{{ product?.title | titlecase }}</h6>
      </a>
      <p>{{ product?.description }}</p>
      <h4>
        ${{ product?.price }}
      </h4>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>

