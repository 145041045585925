import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public categories: any = [];
  public collapse: boolean = true;
  public tag;

  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.filterbyCategory();
  }

  filterbyCategory() {
   this.tag = localStorage.getItem("tag");
    this.categories = this.afs.collection('Categories', ref => ref.where('tag', '==', `${this.tag}`)).valueChanges();
  }

}
