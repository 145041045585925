<div class="main-navbar">
  <div id="mainnav">
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-right">
          <span>Back</span>
          <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
        </div>
      </li>

      <li>
        <a routerLink="/home/buscador" style="text-decoration:none;">
          <h2>Catalogo</h2>
        </a>
      </li>
      <!-- <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">

        <a href="javascript:void(0)" class="nav-link" *ngIf="menuItem.type === 'sub'"
            (click)="toggletNavActive(menuItem)">
          {{ menuItem.title | translate }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <a [routerLink]="menuItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link"
          *ngIf="menuItem.type === 'link'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extTabLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>


        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
          <li *ngFor="let childrenItem of menuItem.children">

            <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'"
                (click)="toggletNavActive(childrenItem)">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>

            <a [routerLink]="childrenItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              *ngIf="childrenItem.type === 'link' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>

            <a href="{{ childrenItem.path }}"
              *ngIf="childrenItem.type === 'extLink' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>

            <a href="{{ childrenItem.path }}" target="_blank"
              *ngIf="childrenItem.type === 'extTabLink' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>


            <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
              <li *ngFor="let childrenSubItem of childrenItem.children">

                <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                  *ngIf="childrenSubItem.type === 'link' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>

                <a href="{{ childrenSubItem.path }}"
                  *ngIf="childrenSubItem.type === 'extLink' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>

                <a href="{{ childrenSubItem.path }}" target="_blank"
                  *ngIf="childrenSubItem.type === 'extTabLink' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <div class="mega-menu-container" *ngIf="menuItem.megaMenu && menuItem.children">
          <div class="container">
            <div class="row">
              <div class="col mega-box" *ngFor="let childrenItem of menuItem.children">
                <div class="link-section">
                  <div class="menu-title">
                    <h5>
                        {{ childrenItem.title | translate }}
                        <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                    </h5>
                  </div>
                  <div class="menu-content opensubmegamenu">
                    <ul *ngIf="childrenItem.children">
                      <li *ngFor="let childrenSubItem of childrenItem.children">

                          <a href="javascript:void(0)" *ngIf="childrenSubItem.type === 'sub'">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>

                          <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            *ngIf="childrenSubItem.type === 'link' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>

                          <a href="{{ childrenSubItem.path }}"
                            *ngIf="childrenSubItem.type === 'extLink' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>

                          <a href="{{ childrenSubItem.path }}" target="_blank"
                            *ngIf="childrenSubItem.type === 'extTabLink' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li> -->
    </ul>
  </div>
</div>
