<!--footer section -->
<footer [class]="class">
  <div class="sub-footer">
    <div class="container">
      <div class="row" style="border-top:1px solid black;">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="footer-end">
            <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} powered by Orvity</p>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="payment-card-bottom">
            <ul>
              <li>
                <a href="https://play.google.com/store/apps/details?id=com.orvity.orvityD&hl=es_MX"><img
                    src="assets/images/logo1.png" style="width:30px;height:30px;" alt=""></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--footer section end -->